import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Layout from "../Layout/Layout";
import routes from "../../constants/routes";

import NotFound from "../../pages/NotFound/NotFound";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import Modal from "../Modal/Modal";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };
  }

  componentWillMount() {
    this._hidePreloader();
  }

  componentWillUnmount() {
    clearTimeout(this.preloaderTimeout);
  }

  _hidePreloader() {
    const preloader = window.document.getElementById("global-preloader");
    if (preloader) {
      preloader.classList.add("loading-screen--hidden");
      this.preloaderTimeout = setTimeout(() => {
        preloader.remove();
        this.setState({
          loaded: true
        });
      }, 500);
    } else {
      this.setState({
        loaded: true
      });
    }
  }

  render() {
    return (
      <Router>
        <ScrollToTop>
          <Route
            render={({ location, history }) => (
              <Layout history={history}>
                <TransitionGroup>
                  {this.state.loaded ? (
                    <CSSTransition
                      key={location.key}
                      classNames="fade"
                      timeout={{ enter: 480, exit: 240 }}
                    >
                      <section className="route-section">
                        <Switch component="div" location={location}>
                          {routes.map((route, i) => (
                            <Route
                              key={route.link}
                              path={route.link}
                              component={route.component}
                              exact
                            />
                          ))}
                          <Route component={NotFound} />
                        </Switch>
                      </section>
                    </CSSTransition>
                  ) : null}
                </TransitionGroup>
              </Layout>
            )}
          />
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
