import React, { Component } from "react";
import PropTypes from "prop-types";

import BurgerBtn from "./BurgerBtn";
import BurgerContent from "./BurgerContent";

class Burger extends Component {
  static propTypes = {
    isActive: PropTypes.bool,
    menuItems: PropTypes.arrayOf(
      PropTypes.shape({
        link: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired
      })
    ),
    onToggle: PropTypes.func
  };

  static defaultProps = {
    isActive: false,
    menuItems: [],
    onToggle: f => f
  };

  constructor(props) {
    super(props);

    this.props.history.listen((location, action) => {
      this.setState({ isActive: false });
      document.body.classList.remove("burger-is-active");
    });

    this.state = {
      isActive: props.isActive
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(event) {
    if (event) {
      event.preventDefault();
    }
    const isActive = !this.state.isActive;
    this.setState({ isActive });
    this.props.onToggle(isActive);

    if (isActive) {
      document.body.classList.add("burger-is-active");
    } else {
      document.body.classList.remove("burger-is-active");
    }
  }

  render() {
    const { isActive } = this.state;
    return (
      <div className="burger">
        <BurgerBtn isActive={isActive} onClick={this.toggle} />
        <BurgerContent isActive={isActive} menuItems={this.props.menuItems} />
      </div>
    );
  }
}

export default Burger;
