import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Burger from "../Burger/Burger";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import Logo from "../Logo/Logo";

const Header = ({
  menuItems,
  history,
  languages = [],
  activeLanguage = ""
}) => (
  <header className="header">
    <div className="fullwidth-wrapper">
      <div className="header__logo">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className="header__nav">
        <div className="grid-container grid-container--v-center grid-container--h-end">
          <div className="grid-col grid-col-auto">
            <Burger history={history} menuItems={menuItems} />
          </div>
          <div className="grid-col grid-col-auto">
            <LanguageSwitcher
              languages={languages}
              activeLanguage={activeLanguage}
            />
          </div>
        </div>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ),
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ),
  activeLanguage: PropTypes.string
};

export default Header;
