import Home from "../pages/Home/Home";
import About from "../pages/About/About";
import Portfolio from "../pages/Portfolio/Portfolio";
import Contacts from "../pages/Contacts/Contacts";

export default [
  { link: "/", text: "Главная", component: Home },
  { link: "/about", text: "Об агенстве", component: About },
  { link: "/portfolio", text: "Портфолио", component: Portfolio },
  { link: "/contacts", text: "Контакты", component: Contacts },
];
