import React from "react";

const SideInfo = () => (
  <aside className="side-info">
    <div className="side-info__content">
      <a href="https://www.behance.net/" className="side-info-link">
        <i className="icon-behance" />
      </a>
      <a href="https://www.vk.com/" className="side-info-link">
        <i className="icon-vk" />
      </a>
      <a href="/brief.docx" className="side-info-link" download>
        <span className="color-accent">ЗАПОЛНИТЬ</span> БРИФ
      </a>
    </div>
  </aside>
);

export default SideInfo;
