import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import ContactForm from "../../components/ContactForm/ContactForm";
import Footer from "../../components/Footer/Footer";

class Contacts extends PureComponent {

  render() {
    return (
      <div>
        <div className="page-contacts page page--fullscreen page--with-padding">
          <Helmet>
            <title>WEB.MEDIA | Контакты</title>
          </Helmet>
          <div className="page__bg page__bg--moon lines-bg lines-bg--default">
            <div className="lines-bg__wrapper page-content-wrapper">
              <div className="lines-bg__line" />
              <div className="lines-bg__line" />
              <div className="lines-bg__line" />
              <div className="lines-bg__line" />
            </div>
          </div>
          <div className="page-contacts__wrapper margin-from-header page-content-wrapper">
            <div className="page-contacts__container grid-container grid-container--no-gutter grid-container--v-stretch">
              <div className="page-contacts__col grid-col grid-col--v-gutter grid-col-6-md grid-col-4-lg">
                <div className="page-contacts__info">
                  <h1 className="page-contacts__title" style={{ fontSize: "2.5vw" }}>Контакты</h1>
                  <div className="page-contacts-group">
                    <a href="tel:+79013155552" className="page-contacts__phone">
                      +7 (901) 315-55-52
                    </a>
                    <a
                      href="mailto:info@web.media"
                      className="page-contacts__email"
                    >
                      info@web.media
                    </a>
                  </div>
                  <div className="page-contacts-group">
                    <div className="page-contacts-group__title page-contacts__icon-text icon-text">
                      <div className="icon-text__icon icon-location" />
                      <div className="icon-text__text">Наш офис здесь:</div>
                    </div>
                    Санкт-Петербург, Лиговский проспект 56Г
                  </div>
                </div>
              </div>
              <div className="page-contacts__map page-contacts__col grid-col grid-col--v-gutter grid-col-6-md grid-col-8-lg">
                <YMaps>
                  <Map width="100%" height="100%" defaultState={{ center: [59.923816, 30.360056], zoom: 14 }}>
                    <Placemark geometry={[59.923816, 30.360056]} options={{ preset: "islands#darkGreenDotIcon" }} />
                  </Map>
                </YMaps>
              </div>
            </div>
          </div>
        </div>
        <ContactForm hasContactsInfo={false} />
        <Footer />
      </div>
    );
  }
}

export default Contacts;