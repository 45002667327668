import React, { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import styles from './Modal.module.scss';
const modalRoot = document.getElementById('modal-root');

const Modal = ({ text }) => {
    const container = useMemo(() => document.createElement('div'), []);

    useEffect(() => {
        modalRoot.appendChild(container);
        return () => modalRoot.removeChild(container);
    }, [container])

    return (
        createPortal(
            <div className={styles.formOverlay}>
                <div className={`wrapper styles.overflowContainer`}>
                    <div className={styles.modal}>
                        <div className={styles.title}>{text}</div>
                    </div>
                </div>
            </div>,
            container
        )
    );
}

export default Modal;