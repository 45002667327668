import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import portfolioItems from "../../constants/portfolioItems";
import portfolioCategories from "../../constants/portfolioCategories";

import PrimaryCheckboxBtn from "../../components/PrimaryCheckboxBtn/PrimaryCheckboxBtn";
import PortfolioCard from "../../components/PortfolioCard/PortfolioCard";
import ContactForm from "../../components/ContactForm/ContactForm";
import Footer from "../../components/Footer/Footer";

class PortfolioCards extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        categories: PropTypes.arrayOf(PropTypes.number),
        link: PropTypes.string,
      })
    ),
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired
      })
    ),
    activeCategoryId: PropTypes.number
  };

  static defaultProps = {
    items: [],
    categories: [],
    activeCategoryId: 0
  };

  constructor(props) {
    super(props);

    let items = [];
    if (!!props.categoryId) {
      items = props.items.filter(item => {
        return item.categories.includes(props.categoryId);
      });
    } else {
      items = props.items;
    }

    this.state = {
      currentItems: items,
      activeCategoryId: props.activeCategoryId
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.activeCategoryId !== nextState.activeCategoryId;
  }

  setCategory = categoryId => {
    categoryId = parseInt(categoryId, 10);
    let items = [];
    if (!!categoryId) {
      items = this.props.items.filter(item => {
        return item.categories.includes(categoryId);
      });
    } else {
      items = this.props.items;
    }

    this.setState({
      currentItems: items,
      activeCategoryId: categoryId
    });
  };

  getCategoryById(categoryId) {
    return this.props.categories.find(category => category.id === categoryId);
  }

  handleFilterRadio = e => {
    this.setCategory(e.target.value);
  };

  renderFilter(filter) {
    return (
      <PrimaryCheckboxBtn
        key={filter.id}
        name="portfolio-category"
        type="radio"
        value={filter.id}
        text={filter.title}
        isChecked={this.state.activeCategoryId === filter.id}
        onChange={this.handleFilterRadio}
      />
    );
  }

  renderFilters(filters) {
    const renderedFilters = filters.map(filter => this.renderFilter(filter));
    return (
      <div className="portfolio-cards__filters">
        <PrimaryCheckboxBtn
          name="portfolio-category"
          type="radio"
          value={0}
          text="Все проекты"
          isChecked={this.state.activeCategoryId === 0}
          onChange={this.handleFilterRadio}
        />
        {renderedFilters}
      </div>
    );
  }

  renderCard(card, i) {
    let marginTop = 0;

    if (!!i) {
      marginTop = Math.floor(Math.random() * 500) + 200;
    }

    return <PortfolioCard {...card} key={card.id} marginTop={marginTop} />;
  }

  renderCards(cards) {
    const renderedCards = cards.map((card, i) => this.renderCard(card, i));
    return (
      <div className="portfolio-cards__container grid-container grid-container--no-gutter grid-container--v-stretch">
        {renderedCards}
      </div>
    );
  }

  render() {
    return (
      <div className="portfolio-cards">
        {this.renderFilters(this.props.categories)}
        {this.renderCards(this.state.currentItems)}
      </div>
    );
  }
}

const Portfolio = () => {
  return (
    <div>
      <Helmet>
        <title>WEB.MEDIA | Портфолио</title>
      </Helmet>
      <div className="page-portfolio page page--fullscreen page--with-padding">
        <div className="page__bg page__bg--moon lines-bg lines-bg--default">
          <div className="lines-bg__wrapper page-content-wrapper">
            <div className="lines-bg__line" />
            <div className="lines-bg__line" />
            <div className="lines-bg__line" />
            <div className="lines-bg__line" />
          </div>
        </div>
        <div className="page-content-wrapper">
          <h1 className="page__top-name">
            <span>Портфолио</span>
          </h1>
          <PortfolioCards
            items={portfolioItems}
            categories={portfolioCategories}
          />
        </div>
      </div>
      <ContactForm />
      <Footer />
    </div>
  );
};

export default Portfolio;
