import React, { Component } from "react";
import PropTypes from "prop-types";
import FlipMove from "react-flip-move";

class LanguageSwitcher extends Component {
  static propTypes = {
    languages: PropTypes.array,
    activeLanguage: PropTypes.string,
    onChangeLanguage: PropTypes.func
  };

  static defaultProps = {
    languages: [],
    activeLanguage: "",
    onChangeLanguage: f => f
  };

  constructor(props) {
    super(props);

    let languages, activeLanguage;
    if (this.props.activeLanguage) {
      languages = this.sortLanguagesByActive(
        this.props.activeLanguage,
        this.props.languages
      );
      activeLanguage = this.props.activeLanguage;
    } else {
      languages = this.props.languages;
      if (languages) {
        activeLanguage = languages[languages.length - 1].id;
      }
    }

    this.state = {
      languages: languages,
      activeLanguage: activeLanguage
    };
  }

  sortLanguagesByActive(activeId, languages = this.state.languages.slice()) {
    return languages.sort(lang => (lang.id === activeId ? 1 : -1));
  }

  changeLanguage(e, id) {
    e.preventDefault();
    const languages = this.sortLanguagesByActive(id);
    const activeLanguage = id;
    this.setState({
      languages,
      activeLanguage
    });
    this.props.onChangeLanguage();
  }

  render() {
    return (
      <FlipMove className="language-switcher">
        {this.state.languages.map(language => {
          const className =
            language.id === this.state.activeLanguage
              ? "language-switcher__item active"
              : "language-switcher__item";
          return (
            <a
              key={language.id}
              data-key={language.id}
              className={className}
              onClick={e => this.changeLanguage(e, language.id)}
              data-width
            >
              {language.text}
            </a>
          );
        })}
      </FlipMove>
    );
  }
}

export default LanguageSwitcher;
