import React, { PureComponent, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";
import Waypoint from "react-waypoint";
import classNames from "classnames";

import ContactForm from "../../components/ContactForm/ContactForm";
import Footer from "../../components/Footer/Footer";

import { getRandomInt, getRandomArbitrary, scrollIt } from "../../utils/helpers";

import prices from "../../constants/prices";

const AppearHOC = WrappedComponent => {
  return class extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        isVisible: false,
        isActive: false
      };
      this._isAnimated = false;
    }

    onEnter = () => {
      if (this.state.isActive || this.state.isVisible || this._isAnimated)
        return;
      this.setState({
        isActive: true,
        isVisible: true
      });
    };

    onLeave = () => {
      if (!this.state.isActive || !this.state.isVisible || this._isAnimated)
        return;
      this.setState({
        isActive: false
      });
      this._isAnimated = true;
    };

    render() {
      return (
        <Waypoint
          onEnter={this.onEnter}
          onLeave={this.onLeave}
          scrollableAncestor={window}
        >
          <div>
            <WrappedComponent {...this.props} {...this.state} />
          </div>
        </Waypoint>
      );
    }
  };
};

const AboutCards = AppearHOC(({ isVisible = true, isActive = false }) => {
  const className = classNames(
    "about-cards",
    "page-content-wrapper",
    { visible: isVisible },
    {
      active: isActive
    }
  );

  return (
    <section className={className}>
      <div className="about-cards-overlay-image">
        <img src={require('../../images/about/about-david-back.svg')} alt="" className="about-cards-overlay-image__back" />
        <img src={require('../../images/about/about-david.png')} alt="" className="about-cards-overlay-image__front" />
      </div>
      <img src={require('../../images/about/webmedia-text.svg')} alt="" className="about-cards__back-image" />
      <div className="about-cards__content">
        <div className="about-cards__wrapper">
          <div className="about-cards-item">
            <div className="about-cards-item__image-container"
              style={{
                backgroundColor: '#1e1e1e'
              }}>
              <div
                className="about-cards-item__image"
                style={{
                  backgroundImage: `url(${require("../../images/about/cards/dev.png")})`
                }}
              />
            </div>
            <div className="about-cards-item__content">
              <h3 className="about-cards-item__title">Разработка</h3>
              <div className="about-cards-item__description">
                В современном мире сайт не только формирует имидж компании, но и повышает лояльность будущих покупателей. Красивый и удобный сайт, учитывающий все особенности пользователей и построенный по грамотной маркетинговой модели, способен обеспечить высокую конверсию посетителей в клиентов.
              </div>
              <div className="about-cards-item__bottom">
                <a className="about-cards-item__more-btn">Подробнее</a>
              </div>
            </div>
          </div>
          <div className="about-cards-item">
            <div className="about-cards-item__image-container"
              style={{
                backgroundColor: '#171717'
              }}>
              <div
                className="about-cards-item__image"
                style={{
                  backgroundImage: `url(${require("../../images/about/cards/design.png")})`
                }}
              />
            </div>
            <div className="about-cards-item__content">
              <h3 className="about-cards-item__title">ДИЗАЙН</h3>
              <div className="about-cards-item__description">
                Детально продуманный дизайн способен не только привлечь и удержать внимание посетителя, но и сделать правильный акцент на ваших преимуществах. Грамотная структура и классификация вашего товара в сочетании с привлекательным интерфейсом обеспечат вам непрерывный поток заявок.
              </div>
              <div className="about-cards-item__bottom">
                <a className="about-cards-item__more-btn">Подробнее</a>
              </div>
            </div>
          </div>
          <div className="about-cards-item">
            <div className="about-cards-item__image-container"
              style={{
                backgroundColor: '#191919'
              }}>
              <div
                className="about-cards-item__image"
                style={{
                  backgroundImage: `url(${require("../../images/about/cards/outsource.png")})`
                }}
              />
            </div>
            <div className="about-cards-item__content">
              <h3 className="about-cards-item__title">АУТСОРСИНГ</h3>
              <div className="about-cards-item__description">
                Разработка, поддержка и полное сопровождение проектов от А до Я. Полная отчетность о проделанной работе, четкое соблюдение сроков, прозрачная аналитика, постоянный мониторинг потребностей пользователей продукта. Высокий уровень технической компетенции и ответственности специалистов обеспечат выполнение проекта на высшем уровне.
              </div>
              <div className="about-cards-item__bottom">
                <a className="about-cards-item__more-btn">Подробнее</a>
              </div>
            </div>
          </div>
          <div className="about-cards-item">
            <div className="about-cards-item__image-container"
              style={{
                backgroundColor: '#141414'
              }}>
              <div
                className="about-cards-item__image"
                style={{
                  backgroundImage: `url(${require("../../images/about/cards/seo.png")})`
                }}
              />
            </div>
            <div className="about-cards-item__content">
              <h3 className="about-cards-item__title">SEO</h3>
              <div className="about-cards-item__description">
                Выводим любые сайты в топ поисковых систем – Яндекс и Google. Разрабатываем комплексную стратегию СЕО-оптимизации и продвижения веб-сайта заказчика. Приводим только целевой трафик, устраняем ошибки, делаем сайт максимально удобным и понятным для пользователя, наполняем смысловым контентом.
              </div>
              <div className="about-cards-item__bottom">
                <a className="about-cards-item__more-btn">Подробнее</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

const AboutStartText = AppearHOC(({ isVisible = true, isActive = false }) => {
  const className = classNames(
    "about-start-text",
    "page-content-wrapper",
    {
      active: isActive
    },
    {
      visible: isVisible
    }
  );

  return (
    <section className={className}>
      <h2 className="about-start-text__title">СОЗДАЁМ ВАШ УСПЕХ</h2>
      <div className="about-start-text__description">
        <p>
          Web.Media - студия, предоставляющая полный спектр услуг в сфере
          дизайна, разработки, продвижения сайтов.
        </p>
      </div>
      <div className="about-start-text__cols grid-container grid-container--v-start grid-container--no-gutter">
        <div className="grid-col grid-col-6-sm grid-col-4-md about-start-text-col">
          <h3 className="about-start-text-col__title">
            <span>АНАЛИЗИРУЕМ</span>
          </h3>
          <div className="about-start-text-col__description">
            Проведем бесплатный аудит вашего сайта, выявим основные ошибки в структуре и интерфейсе.
          </div>
        </div>
        <div className="grid-col grid-col-6-sm grid-col-4-md about-start-text-col">
          <h3 className="about-start-text-col__title">
            <span>РАЗРАБАТЫВАЕМ</span>
          </h3>
          <div className="about-start-text-col__description">
            Разрабатываем продающие сайты по воронке продаж с уникальной структурой и дизайном под ключ.
          </div>
        </div>
        <div className="grid-col grid-col-6-sm grid-col-4-md about-start-text-col">
          <h3 className="about-start-text-col__title">
            <span>СОВЕРШЕНСТВУЕМ</span>
          </h3>
          <div className="about-start-text-col__description">
            Правим структуру и дизайн каждый страницы сайта в соответствии с анализом поведения пользователя.
          </div>
        </div>
      </div>
    </section>
  );
});

const AboutBigImageSection = AppearHOC(
  ({ isVisible = true, isActive = false }) => {
    const className = classNames(
      "about-big-image-section",
      "page-content-wrapper",
      {
        active: isActive
      },
      {
        visible: isVisible
      }
    );

    return (
      <section className={className}>
        <div className="grid-container grid-container--v-stretch grid-container--no-gutter">
          <div className="grid-col grid-col-4-md about-big-image-section__col vertical-grid-container">
            <div className="about-big-image-section__title">
              <span>СОБИРАЕМ КЛИКИ ДЛЯ ВАШИХ САЙТОВ</span>
            </div>
            <div className="about-big-image-section__description">
              Делаем ваш бизнесс эффективнее, а сайты популярнее. Не просто
              разрабатываем веб-проекты, а решаем бизнесс-задачи, создаём
              уникальный дизайн, повышаем приток клиентов, совершенствуем долю
              конверсии. Наша работа - ваш успех.
            </div>
          </div>
          <div className="grid-col grid-col-flex-md about-big-image-section__col">
            <div className="about-big-image-section__image-container">
              <img
                className="about-big-image-section__image"
                src={require("../../images/about/woman.png")}
                alt="woman"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
);

const AboutProjects = AppearHOC(({ isVisible = true, isActive = false }) => {
  const className = classNames(
    "about-projects-section",
    "page-content-wrapper",
    {
      active: isActive
    },
    {
      visible: isVisible
    }
  );

  return (
    <section className={className}>
      <div className="grid-container grid-container--v-stretch grid-container--no-gutter">
        <div className="grid-col grid-col-6-sm grid-col-4-md">
          <div className="about-projects-section__big-button-wrapper">
            <Link
              to="/portfolio"
              className="about-projects-section__big-button"
            >
              Портфолио
            </Link>
          </div>
        </div>
        <div className="grid-col grid-col-4-md hidden-before-md">
          <div className="about-projects-section__line" />
        </div>
        <div className="grid-col grid-col-6-sm grid-col-4-md overflow-hidden">
          <div className="about-projects-section__content">
            <div className="about-projects-section__title">
              Наши проекты
              <br />
              говорят сами за себя
            </div>
            <div className="about-projects-section__description">
              Портфолио с успешно выполненными проектами – это визитная карточка нашей компании. Благодаря ему вы поймете, что все работы нашей студии выполнены на высшем уровне.
              <br />
              Жмите сюда, чтобы увидеть список работ по разработке, поддержке и поисковой оптимизации сайтов от Web.Media
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

const AboutVideo = AppearHOC(({ isVisible = true, isActive = false }) => {
  const className = classNames(
    "about-video-section",
    {
      active: isActive
    },
    {
      visible: isVisible
    }
  );
  return (
    <section className={className}>
      <div className="page-content-wrapper p-relative">
        <div
          className="about-video-section__image"
          style={{ backgroundImage: `url('${require("../../images/about/ice-cream.png")}')` }}
        />
        <div className="about-video-section__text">красивое видео</div>
        <div className="about-video-section__box">
          <div className="about-video-section__wrapper">
            <div className="about-video-section__video-container" />
          </div>
        </div>
      </div>
    </section>
  );
});

const AboutImmersion = AppearHOC(({ isVisible = true, isActive = false }) => {
  const className = classNames(
    "about-immersion-section",
    "fullscreen-padding",
    {
      active: isActive
    },
    {
      visible: isVisible
    }
  );

  return (
    <section className={className}>
      <div className="page-content-wrapper">
        <div className="about-immersion-section__container grid-container grid-container--v-center grid-container--no-gutter">
          <div className="grid-col grid-col-6-md hidden-before-md">
            <img
              src={require("../../images/about/diver.png")}
              alt="diver"
              className="about-immersion-section__image"
            />
          </div>
          <div className="grid-col grid-col-6-md">
            <h3 className="about-immersion-section__title">
              <div className="about-immersion-section__title-inner">
                Полное погружение <br /> в каждый проект
              </div>
            </h3>
            <div className="about-immersion-section__description">
              <div className="about-immersion-section__description-inner">
                Работать на конвейере – это не про нас. Каждый проект – это полное погружение в бизнес клиента. Проводим глубокий анализ сайта на основе аналитических данных, сегментацию целевой аудитории, исследуем конкурентов, устраняем ошибки, выстраиваем четкую стратегию продвижения.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

function getCardItem(cardImage, key) {
  const baseCardBgColorOpacity = 0.1;
  const bgColorOpacity = cardImage
    ? baseCardBgColorOpacity * getRandomArbitrary(0.6, 1)
    : baseCardBgColorOpacity * getRandomArbitrary(0, 0.5);
  const backgroundColor = `rgba(255, 255, 255, ${bgColorOpacity})`;
  const transitionDelay = getRandomArbitrary(0.6, 2.12) + "s";
  // const animationDelay = getRandomArbitrary(2, 3) + "s";
  return cardImage ? (
    <div
      className="about-technologies-section-card"
      style={{ backgroundColor, transitionDelay }}
      key={key}
    >
      <div className="about-technologies-section-card__inner">
        <img src={cardImage} alt="" />
      </div>
    </div>
  ) : (
      <div
        className="about-technologies-section-card"
        style={{ backgroundColor, transitionDelay }}
        key={key}
      >
        <div className="about-technologies-section-card__inner" />
      </div>
    );
}

function renderTechnologiesCards(cardsImages) {
  let currentCardOffset = 0;
  const cards = [];

  cardsImages.forEach(image => {
    cards.push(getCardItem(image, cards.length.toString()));

    while (currentCardOffset > 0) {
      cards.push(getCardItem(null, cards.length.toString()));
      currentCardOffset--;
    }

    currentCardOffset = getRandomInt(0, 5);
  });

  return cards;
}

const AboutTechnologies = AppearHOC(
  ({ isVisible = true, isActive = false }) => {
    const cardsImages = [
      require("../../images/about/logos/ai.svg"),
      require("../../images/about/logos/ap.svg"),
      require("../../images/about/logos/html5.svg"),
      require("../../images/about/logos/css3.svg"),
      require("../../images/about/logos/sass.svg"),
      require("../../images/about/logos/php.svg"),
      require("../../images/about/logos/wordpress.svg"),
      require("../../images/about/logos/laravel.svg"),
      require("../../images/about/logos/nodejs.svg"),
      require("../../images/about/logos/webpack.svg"),
      require("../../images/about/logos/react.svg"),
      require("../../images/about/logos/bitrix.svg"),
      require("../../images/about/logos/git.svg")
    ];

    const className = classNames(
      "about-technologies-section",
      "page-content-wrapper",
      {
        active: isActive
      },
      {
        visible: isVisible
      }
    );

    return (
      <section className={className}>
        <div className="grid-container grid-container--v-stretch grid-container--no-gutter">
          <div className="about-technologies-section__title-column grid-col grid-col--v-gutter grid-col-8-md">
            <h2 className="about-technologies-section__title">
              <div className="about-technologies-section__title-inner">
                Технологии
              </div>
            </h2>
            <div className="about-technologies-section__subtitle">
              <div className="about-technologies-section__subtitle-inner">
                которые мы используем
              </div>
            </div>
          </div>
          <div className="grid-col grid-col--v-gutter grid-col-4-md">
            <div className="about-technologies-section__description">
              <div className="about-technologies-section__description-inner">
                Для нас важно не просто идти в ногу со временем, но и быть на
                шаг впереди, чтобы обеспечить перспективу развития для вашего
                бизнесса. Поэтому мы постоянно учимся, эксперементируем,
                используем все доступные средства и новые технологии для
                достижения максимально привлекательного результата.
              </div>
            </div>
          </div>
        </div>
        <div className="about-technologies-section__cards">
          {renderTechnologiesCards(cardsImages)}
          <div className="about-technologies-section-card" />
        </div>
        <img src={require('../../images/about/meduza.png')} alt="" className="about-technologies-section__front-image" />
      </section>
    );
  }
);

const AboutNotAfraid = AppearHOC(({ isVisible = true, isActive = false }) => {
  const className = classNames(
    "about-not-afraid-section",
    "page-content-wrapper"
    // {
    //   active: isActive
    // },
    // {
    //   visible: isVisible
    // }
  );

  return (
    <section className={className}>
      <h2 className="about-not-afraid-section__title">
        <div className="about-not-afraid-section__title-inner">
          Не боимся <br /> сложностей
        </div>
      </h2>

      <div className="about-not-afraid-section__description">
        <div className="about-not-afraid-section__description-inner">
          Даже если у вас есть только одни лимоны, мы сделаем для вас самый
          отменный рецепт лимонада и качественный сайт для его продажи. А
          WOW-эффект презентации гарантировано обеспечат наши дизайнеры.
        </div>
      </div>
      <div className="about-not-afraid-section__images">
        <img
          src={require("../../images/about/not-afraid.png")}
          alt=""
          className="about-not-afraid-section__image-left"
        />
        <img
          src={require("../../images/about/column.png")}
          alt=""
          className="about-not-afraid-section__image-right hidden-before-sm"
        />
      </div>
    </section>
  );
});

const AboutPrices = AppearHOC(({ isVisible = true, isActive = false, contactFormY }) => {
  console.log(contactFormY);
  const className = classNames(
    "about-prices-section",
    "page-content-wrapper"
    // {
    //   active: isActive
    // },
    // {
    //   visible: isVisible
    // }
  );

  return (
    <section id="uslugi" className={className}>
      <h2 className="about-prices-section__title">
        Сколько
        <br />
        стоит?
      </h2>
      <div className="about-prices-section__description grid-col-6-sm grid-col-4-md">
        Стоимость каждого сайта рассчитывается индивидуально, исходя из особенностей проекта. Заказывая у нас, вы можете быть уверены, что получите максимально быстрый и эффективный результат, вне зависимости от того, что вы заказали – простой сайт-визитка или интернет-магазин.
        <br />
        Для более подробной информации, обращайтесь к нам по телефону
        <a href="tel:+79650484336">+7 (901) 315-55-52</a>, или пишите по адресу <a href="mailto:info@web.media">info@web.media</a>.
      </div>
      <div className="about-prices-section__prices">
        {prices.map((item, i) => (
          <div key={i} className="about-prices-section-price">
            <div className="grid-container grid-container--v-start grid-container--no-gutter">
              <div className="grid-col grid-col--v-gutter grid-col-6-sm grid-col-4-md">
                <div className="about-prices-section-price__text-col">
                  <h3 className="about-prices-section-price__title">
                    {item.title}
                  </h3>
                  <div className="about-prices-section-price__description">
                    {item.description}
                  </div>
                </div>
              </div>
              <div className="grid-col grid-col--v-gutter grid-col-6-sm grid-col-4-md hidden-before-md">
                <div className="about-prices-section-price__line" />
              </div>
              <div className="grid-col grid-col--v-gutter grid-col-6-sm grid-col-4-md">
                <div className="about-prices-section-price__price">
                  {item.price}
                </div>
                <button onClick={() => { scrollIt(contactFormY) }} className="about-prices-section-price__button primary-button primary-button--medium primary-button--white">
                  Оставить заявку
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
});

const About = () => {
  const [contactFormY, setContactFormY] = useState(0);
  const contactFormRef = useRef(null);

  useEffect(() => {
    const updateContactFormY = () => {
      if (contactFormRef.current) {
        setContactFormY(contactFormRef.current.getBoundingClientRect().top)
      }
    }

    updateContactFormY();
  }, []);

  return (
    <div>
      <Helmet>
        <title>WEB.MEDIA | Об агенстве</title>
      </Helmet>
      <div className="page-about page page--fullscreen page--with-padding">
        <div className="page__bg page__bg--moon lines-bg lines-bg--about-page">
          <div className="lines-bg__wrapper page-content-wrapper">
            <div className="lines-bg__line" />
            <div className="lines-bg__line" />
            <div className="lines-bg__line" />
            <div className="lines-bg__line" />
          </div>
        </div>
        <AboutCards />
        <AboutStartText />
        <AboutBigImageSection />
        <AboutProjects />
      </div>
      <AboutVideo />
      <AboutImmersion />
      <div className="page-about page page--fullscreen page--with-padding">
        <AboutTechnologies />
        <AboutNotAfraid />
        <AboutPrices contactFormY={contactFormY} />
      </div>
      <div ref={contactFormRef}>
        <ContactForm />
      </div>
      <Footer />
    </div>
  );
};

export default About;
