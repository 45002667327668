import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import routes from "../../constants/routes";

const BurgerContent = ({ menuItems = routes, isActive = false }) => {
  menuItems = [...menuItems,
  ];
  const className = isActive ? "burger-content active" : "burger-content";
  return (
    <div className={className}>
      <nav className="burger-content__wrapper page-content-wrapper">
        {!menuItems.length ? null : (
          <ul className="burger-content-menu">
            {menuItems.map(menuItem => (
              <li key={menuItem.link}>
                <Link to={menuItem.link}>
                  <span>{menuItem.text}</span>
                </Link>
              </li>
            ))}
            <li>
              <HashLink smooth to="/about#uslugi">
                <span>Услуги</span>
              </HashLink>
            </li>
          </ul>
        )}
        <div className="burger-content-image" />
      </nav>
    </div>
  );
};

export default BurgerContent;
