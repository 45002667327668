import React from "react";
import Header from "../Header/Header";
import SideInfo from "../SideInfo/SideInfo";
import ScrollToTopBtn from "../ScrollToTopBtn/ScrollToTopBtn";

import routes from "../../constants/routes";
import languages from "../../constants/languages";

const Layout = ({ children, history }) => {
  return (
    <div className="layout">
      <Header
        menuItems={routes}
        history={history}
        languages={languages}
        activeLanguage="RU"
      />
      <div className="layout-bottom">
        <div className="fullwidth-wrapper">
          <div className="layout__left">
            <ScrollToTopBtn />
          </div>
          <div className="layout__right">
            <SideInfo />
          </div>
        </div>
      </div>
      <div className="layout-content">{children}</div>
    </div>
  );
};

export default Layout;
