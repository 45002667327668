import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { HashLink } from "react-router-hash-link";
import Parallax from "parallax-js";

class NotFound extends Component {
  constructor(props) {

    super(props);
    this.state = {
      isActive: false,
    };
  }

  goBack = () => {
    this.props.history.goBack();
  }

  componentDidMount() {
    if (window.innerWidth >= 720) {
      this.parallax = new Parallax(this.scene, {
        selector: '.layer',
        pointerEvents: true,
      });
    }

    this.setState({ isActive: true });
  }

  componentWillUnmount() {
    if (this.parallax) {
      this.parallax.disable();
    }
  }

  render() {
    const activeClass = this.state.isActive ? 'active' : '';
    return (
      <div className={`page-not-found page--fullscreen page--with-padding ${activeClass}`} ref={el => this.scene = el}>
        <Helmet>
          <title>WEB.MEDIA | Ошибка 404</title>
        </Helmet>
        <div className="page-not-found__container">
          <h1 className="page-not-found__title text-glitch layer" data-text="404" data-depth="0.1">404</h1>
          <div className="page-not-found__content">
            <div className="page-not-found-box layer" data-depth="0.2">
              <div className="page-not-found-box__title">Sorry, not found</div>
              <div className="page-not-found-box__description">
                Запрашиваемая вами страница находится<br />
                в разработке или вовсе отсутствует. :(<br /><br />
                Приносим извинения за неудобства.
              </div>
              <div className="page-not-found-box__button-container">
                <a onClick={this.goBack} className="primary-button primary-button--medium primary-button--white-alt">Назад</a>
              </div>
            </div>
            <div className="page-not-found__bottom-text layer" data-depth="0.1">
              <HashLink smooth to="/about#send" className="default-link"><span className="color-accent">СВЯЗАТЬСЯ</span> С НАМИ</HashLink>
            </div>
          </div>
        </div>
        <div className="page-not-found-mosaic"><div className="page-not-found-mosaic__cube"></div><div className="page-not-found-mosaic__mask"></div></div>
        <img
          src={require('../../images/404/squid.png')}
          className="page-not-found__squid layer"
          data-depth="0.09"
          alt="Squid"
        />
      </div>
    );
  }
}

export default NotFound;
