export default [
  {
    id: "RU",
    text: "RU"
  },
  {
    id: "EN",
    text: "EN"
  }
];
