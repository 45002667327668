import React from "react";
import PropTypes from "prop-types";

function generateUID() {
  return (
    "_" +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
}

const PrimaryCheckboxBtn = ({
  type = "checkbox",
  name = "",
  value = "",
  text = "",
  isChecked = false,
  onChange = f => f
}) => {
  const uid = generateUID();

  return (
    <div className="primary-checkbox-btn">
      <input
        id={uid}
        type={type}
        name={name}
        value={value}
        checked={isChecked}
        onChange={onChange}
        className="primary-checkbox-btn__input"
      />
      <label htmlFor={uid} className="primary-checkbox-btn__label">
        {text}
      </label>
    </div>
  );
};

PrimaryCheckboxBtn.propTypes = {
  type: PropTypes.oneOf(["checkbox", "radio"]),
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default PrimaryCheckboxBtn;
