export default [
  {
    title: "Сайт-визитка",
    description:
      "Небольшой и самый недорогой сайт, состоящий из одной веб-страницы. Идеально подойдет для тех, кто хочет ярко заявить о себе или опробовать продажи одного товара или услуги.",
    price: "от 70 000 ₽ "
  },
  {
    title: "Лендинг",
    description:
      "Одностраничный сайт, призванный захватить внимание пользователя с первых секунд. Построенный по воронке продаж, лендинг пейдж отлично подойдет тем, кто хочет выжимать максимум заявок при небольших вложениях.",
    price: "от 55 000 ₽ "
  },
  {
    title: "Интернет-магазин",
    description:
      "Разработка интернет-магазинов с мощным функционалом, интеграция платежных систем, привязка более пяти различных служб доставки товара. Настройка адаптивного дизайна, оптимизация сайта под любые устройства, создание удобной системы управления и большого каталога товаров, придерживаясь линейной и удобной структуры. Загрузка товаров на сайт как в ручном, так автоматизированном режиме.",
    price: "от 120 000 ₽ "
  },
  {
    title: "Фирменный стиль",
    description:
      "Разработка индивидуального дизайна сайта, учитывающего не только красивую визуальную составляющую, но и удобство пользователя, а также интуитивно понятный интерфейс.",
    price: "от 35 000 ₽ "
  },
  {
    title: "Доменный брокер",
    description:
      "Мы готовы предоставить десятки доменов для любой ниши, тематики и целевой аудитории под запрос. В нашем распоряжении домены как на кириллице – ru,su, info, com, так и на латинице – красивые и быстро запоминающиеся домены РФ.",
    price: "от 10 000 ₽ "
  },
  {
    title: "Медиабаинг",
    description:
      "Размещение рекламных баннеров на крупных торговых и информационных площадках. Предоставляем рекламные площади на сотнях различных сайтов с учетом вашей целевой аудитории. Подбираем площадку с учетом количества пользователей, уникальных посетителей и проверенности ресурса.",
    price: "от 55 000 ₽ "
  },
  {
    title: "SMM",
    description:
      "Продвижение и ведение социальных сетей, блогов и форумов с нуля. Определение целевой аудитории, проведение анализа ниши, увеличение численности вашей аудитории, проведение таргетированных рекламных кампаний с гарантией результата, создание и улучшение имиджа компании.",
    price: "от 30 000 ₽ "
  }
];
