import React, { Component } from "react";
import PropTypes from "prop-types";
import Waypoint from "react-waypoint";
import { Link } from "react-router-dom";

class PortfolioCard extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.number),
    marginTop: PropTypes.number,
    link: PropTypes.string,
  };

  static defaultProps = {
    id: 0,
    image: "",
    title: "",
    description: "",
    categories: [],
    marginTop: 0
  };

  constructor(props) {
    super(props);
    this.state = {
      isInViewport: false
    };
  }

  onEnter = () => {
    if (this.state.isInViewport) return;
    this.setState({
      isInViewport: true
    });
  };

  render() {
    const { image, title, description, categories, marginTop } = this.props;
    const inViewClass = !this.state.isInViewport
      ? "portfolio-card--hidden"
      : "";
    const gradients = ["1", "2", "3", "4", "5"];
    const randomGradientChoice =
      gradients[Math.floor(Math.random() * gradients.length)];

    return (
      <Waypoint onEnter={this.onEnter}>
        <div
          className={`portfolio-card grid-col grid-col-6-sm grid-col-4-lg ${inViewClass}`}
          style={{
            marginTop: marginTop + "px"
          }}
        >
          <Link to={this.props.link}
            className={`portfolio-card__image-container portfolio-card__image-container--${randomGradientChoice}`}
          >
            <div
              className="portfolio-card__image-container-overlay"
              style={{ backgroundImage: "" }}
            />
            <img src={image} alt={title} />
          </Link>
          <h2 className="portfolio-card__title">{title}</h2>
          <div className="portfolio-card__categories" />
          <div
            className="portfolio-card__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </Waypoint>
    );
  }
}

export default PortfolioCard;
