export default [
  {
    id: 1,
    title: "Автомобили"
  },
  {
    id: 2,
    title: "Медицина"
  },
  {
    id: 3,
    title: "Печать"
  },
  {
    id: 4,
    title: "Недвижимость"
  }
];
