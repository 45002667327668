import categories from "./portfolioCategories";
import image1 from "../images/portfolio/graffiti-canvas.jpg";
import image2 from "../images/portfolio/choco.jpg";
import image3 from "../images/portfolio/graffiti.jpg";
import image4 from "../images/portfolio/vtormet47.jpg";
import image5 from "../images/portfolio/47land.jpg";
import image6 from "../images/portfolio/allonfour.jpg";

export default [
  {
    id: 1,
    image: image1,
    title: "Graffiti печать на хосте",
    description: "Интернет-магазин",
    categories: [categories[2].id],
    link: '/not-found'
  },
  {
    id: 2,
    image: image2,
    title: "Шоколад с логотипом",
    description: "Интернет-магазин",
    categories: [categories[2].id],
    link: '/not-found'
  },
  {
    id: 3,
    image: image3,
    title: "Graffiti",
    description: "Интернет-магазин",
    categories: [categories[2].id],
    link: '/not-found'
  },
  {
    id: 4,
    image: image4,
    title: "Втормет 47",
    description: "Интернет-магазин",
    categories: [categories[0].id],
    link: '/not-found'
  },
  {
    id: 5,
    image: image5,
    title: "47land",
    description: "Интернет-магазин",
    categories: [categories[3].id],
    link: '/not-found'
  },
  {
    id: 6,
    image: image6,
    title: "allonfour.ru",
    description: "Интернет-магазин",
    categories: [categories[1].id],
    link: '/not-found'
  }
];
