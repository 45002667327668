import React from "react";

const Logo = () => (
  <div className="logo">
    {[0, 1, 2, 3].map(item => (
      <svg
        key={item}
        version="1.1"
        id="Слой_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="43.98px"
        height="54.99px"
        viewBox="0 0 43.98 54.99"
        enableBackground="new 0 0 43.98 54.99"
        xmlSpace="preserve"
      >
        <g>
          <polygon
            points="30.358,12.268 30.358,31.703 26.741,29.068 26.741,15.84 23.8,18.746 23.8,26.969 20.194,26.969 20.194,18.941 
                    17.253,16.035 17.253,28.988 13.624,31.631 13.624,12.451 10.682,9.545 10.682,33.773 10.682,36.152 10.682,37.451 21.942,29.213 
                    33.3,37.451 33.3,36.152 33.3,9.361 	"
          />
          <rect x="1.397" y="49.671" width="41.186" height="2.622" />
          <path d="M42.698,44.115H1.282V2.697h41.416V44.115z M4.066,41.331h35.849V5.481H4.066V41.331z" />
        </g>
      </svg>
    ))}
  </div>
);
export default Logo;
