import React, { PureComponent } from "react";
import { scrollIt, getDocumentScrollPosition } from "../../utils/helpers";

class ScrollToTopBtn extends PureComponent {
  state = {
    isVisible: false
  };

  scrollToTop = () => {
    scrollIt(0);
  };

  componentDidMount() {
    window.addEventListener("scroll", () => {
      let pos = getDocumentScrollPosition();

      if (pos.top > 400) {
        this.setState({
          isVisible: true
        });
      } else {
        this.setState({
          isVisible: false
        });
      }
    });
  }

  render() {
    const isVisibleClass = this.state.isVisible ? "visible" : "";
    return (
      <a
        onClick={this.scrollToTop}
        className={`scroll-to-top-btn ${isVisibleClass}`}
      >
        <div className="scroll-to-top-btn__line" />
        <div className="scroll-to-top-btn__text">Наверх</div>
      </a>
    );
  }
}

export default ScrollToTopBtn;
