import React from "react";

const Footer = () => (
  <footer className="footer">
    <div className="footer__wrapper footer__copyright">
      © 2017–2018. Dooh.ru
      <br />
      Все права защищены.
    </div>
  </footer>
);

export default Footer;
