import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import davidBackGradient from "../../images/home/home-david-back.svg";
import davidFrontImage from "../../images/home/home-david-front.png";
import davidLightingImage from "../../images/home/home-david-lighting.png";
import Parallax from "parallax-js";

class Home extends PureComponent {
  state = {
    titleClass: "",
    imageClass: "",
    descriptionClass: ""
  };

  componentDidMount() {
    if (window.innerWidth >= 720) {
      this.parallax = new Parallax(this.scene, {
        // frictionY: 0,
        selector: '.layer'
      });
    }

    this.animationTimeouts = [];
    this.animationTimeouts[0] = setTimeout(() => {
      this.setState({
        titleClass: "active"
      });
    }, 480);

    if (window.innerWidth >= 992)  {
      this.animationTimeouts[1] = setTimeout(() => {
        this.setState({
          imageClass: "active"
        });
      }, 1560);
      this.animationTimeouts[2] = setTimeout(() => {
        this.setState({
          descriptionClass: "active"
        });
      }, 2600);
    } else {
      this.setState({
        imageClass: "active"
      });
      this.animationTimeouts[1] = setTimeout(() => {
        this.setState({
          descriptionClass: "active"
        });
      }, 1560);
    }
  }

  componentWillUnmount() {
    this.animationTimeouts.forEach(timeout => clearTimeout(timeout));
    if (this.parallax) {
      this.parallax.disable();
    }
  }

  render() {
    const { titleClass, imageClass, descriptionClass } = this.state;
    const title = "WEBMEDIA INTERNET- AGENCY";
    const description = "Создаем веб-проекты и доводим их до пика успешности";
    return (
      <div className="page-home page page--fullscreen page--with-padding">
        <Helmet>
          <title>WEB.MEDIA</title>
        </Helmet>
        <div className="page-home__scene page--with-padding" ref={el => this.scene = el}>
          <div className="page__bg page__bg--moon"/>
          <div className={`page-home-mosaic layer ${descriptionClass}`} data-depth="0.07">
            <div className="page-home-mosaic__cube"></div>
            <div className="page-home-mosaic__mask"></div>
          </div>
          <div className="page-home-lighting hidden-before-lg layer"
               data-depth="0.12">
            <img
              src={davidLightingImage}
              className={`page-home-lighting__image ${imageClass}`}
              alt="lighting"
            />
          </div>
          <div className={`page-home-david hidden-before-lg ${imageClass}`}>
            <img
              src={davidBackGradient}
              className="page-home-david__back-gradient layer" data-depth="0.09"
              alt="WEBMEDIA INTERNET- AGENCY"
            />
            <img
              src={davidFrontImage}
              className="page-home-david__back-image layer" data-depth="0.09"
              alt="WEBMEDIA INTERNET- AGENCY"
            />
            <img
              src={davidFrontImage}
              className="page-home-david__front layer" data-depth="0.12"
              alt="WEBMEDIA INTERNET- AGENCY"
            />
          </div>
          <div className="page-home__wrapper wrapper wrapper--without-offset layer" data-depth="0.18">
            <div className="grid-container">
              <div className="page-home__title-col grid-col grid-col-flex-sm">
                <h1 className={`page-home__title words-appear ${titleClass}`} data-text={title}>
                  {title.split("").map((word, i) => (
                    <span
                      key={i}
                      className="words-appear__word"
                      style={{ transitionDelay: Math.random() * 0.5 + "s" }}
                    >
                      {word}
                    </span>
                  ))}
                </h1>
              </div>
              <div className="grid-col grid-col-flex-sm">
                <div
                  className={`page-home__description words-appear ${descriptionClass}`}
                >
                  {description.split(" ").map((word, i) => (
                    <span
                      key={i}
                      className="words-appear__word"
                      style={{ transitionDelay: Math.random() * 0.5 + "s" }}
                    >
                      {word + " "}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link to="/portfolio" className="page-left-page-btn">
          <div className="page-left-page-btn__text">ПОРТФОЛИО</div>
        </Link>
        <Link to="/about" className="page-right-page-btn">
          <div className="page-right-page-btn__text">ОБ АГЕНТСТВЕ</div>
        </Link>
      </div>
    );
  }
}

export default Home;
