import React, { Component } from "react";
import PropTypes from 'prop-types';
import TextField from "../../components/TextField/TextField";
import Waypoint from "react-waypoint";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Modal from "../Modal/Modal";
import { postData } from '../../utils/helpers';

class ContactForm extends Component {
  static defaultProps = {
    hasContactsInfo: true,
  };

  static propTypes = {
    hasContactsInfo: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showModal: false,
      statusText: null,
      isInViewport: false
    };
  }

  onEnter = () => {
    this.setState({
      isInViewport: true
    });
    document.body.classList.add("white-bg");
  };

  onLeave = () => {
    document.body.classList.remove("white-bg");
  };

  showModal = (statusText) => {
    this.setState({ showModal: true, statusText });
    setTimeout(() => this.setState({ showModal: false }), 2000);
  }

  onSubmit = e => {
    e.preventDefault();
    var formData = new FormData(e.target);

    fetch('/send_message.php', {
      method: 'POST',
      body: formData
    })
      .catch((error) => {
        this.showModal('Произошла непредвиденная ошибка, попробуйте еще раз позднее');
      })
      .then(response => response.json())
      .then(
        ({ message = 'Произошла непредвиденная ошибка, попробуйте еще раз позднее' }) => {
          this.showModal(message);
        }
      )

  };

  renderContactsInfoCol(colClass = "contact-form__col grid-col grid-col-4-lg") {
    return (
      <div className={colClass}>
        <div className="contact-form-contacts-group">
          <a href="tel:+79013155552" className="contact-form__phone">
            +7 (901) 315-55-52
          </a>
          <a
            href="mailto:info@web.media"
            className="contact-form__email"
          >
            info@web.media
          </a>
        </div>
        <div className="contact-form-contacts-group">
          <div className="contact-form-contacts-group__title">
            <strong>Мы находимся по адресу:</strong>
          </div>
          <div className="contact-form__icon-text icon-text">
            <div className="icon-text__icon icon-location" />
            <div className="icon-text__text">
              Санкт-Петербург, Лиговский проспект 56Г
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const isVisibleClass = this.state.isInViewport
      ? "contact-form--visible"
      : "";
    const formColClass = this.props.hasContactsInfo
      ? "contact-form__col grid-col grid-col-4-lg"
      : "contact-form__col grid-col grid-col-8-lg";

    return (
      <Waypoint
        onEnter={this.onEnter}
        onLeave={this.onLeave}
        topOffset="86%"
        bottomOffset="13%"
        scrollableAncestor={window}
      >
        <form id="send" onSubmit={this.onSubmit} className={`contact-form ${isVisibleClass}`} >
          <div className="contact-form__lines">
            <div className="contact-form__line" />
            <div className="contact-form__line" />
            <div className="contact-form__line" />
            <div className="contact-form__line" />
          </div>
          <div className="contact-form__content">
            <div className="contact-form__wrapper p-relative page-content-wrapper">
              <div className="contact-form__v-lines">
                <div className="contact-form__v-line" />
                <div className="contact-form__v-line" />
                <div className="contact-form__v-line" />
                <div className="contact-form__v-line" />
              </div>
              <h2 className="contact-form__title">
                Обсудим ваш проект подробнее?
              </h2>
              <div className="contact-form__container grid-container grid-container--no-gutter grid-container--v-end">
                <div className={formColClass}>
                  <div className="contact-form__description">
                    Оставьте заявку или свяжитесь с нами удобным для вас
                    способом. Ответим в течение 15 минут!
                  </div>
                  <TextField
                    name="name"
                    label="Ваше имя"
                    style={{ marginTop: "1.5rem" }}
                    required
                  />
                  <TextField
                    name="phone_email"
                    label="телефон / e-mail"
                    style={{ marginTop: "1.5rem" }}
                    required
                  />
                  <TextField
                    name="message"
                    label="При желании,оставьте здесь комментарий к заявке"
                    multiline={true}
                    style={{ marginTop: "3.5rem" }}
                  />
                </div>
                <div className="contact-form__col text-align-center grid-col grid-col-4-lg">
                  <button
                    className="primary-button primary-button--medium primary-button--black"
                    style={{ marginTop: "2rem" }}
                  >
                    Отправить
                  </button>
                </div>
                {(this.props.hasContactsInfo) ? this.renderContactsInfoCol() : null}
              </div>
            </div>
          </div>
          <CSSTransition
            in={this.state.showModal}
            timeout={300}
            unmountOnExit
            classNames="modal-show"
            timeout={{ exit: 1000 }}
          >
            <Modal text={this.state.statusText} />
          </CSSTransition>
        </form>
      </Waypoint>
    );
  }
}

export default ContactForm;
