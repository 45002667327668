import React from "react";

const BurgerBtn = ({ isActive = false, onClick = f => f }) => {
  const className = isActive ? "burger-btn active" : "burger-btn";
  return (
    <a className={className} onClick={onClick}>
      <div className="burger-btn-text">
        <div className="burger-btn-text__container">
          <div className="burger-btn-text__default">Меню</div>
          <div className="burger-btn-text__close">Закрыть</div>
        </div>
      </div>
    </a>
  );
};

export default BurgerBtn;
