import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class TextField extends Component {
  static propTypes = {
    type: PropTypes.oneOf(["text", "number", "email", "tel"]),
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    theme: PropTypes.oneOf(["black"]),
    vOffset: PropTypes.bool,
    fullwidth: PropTypes.bool,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
  };

  static defaultProps = {
    type: "text",
    name: "",
    label: "",
    value: "",
    rows: 4,
    theme: "black",
    vOffset: false,
    fullwidth: false,
    multiline: false,
    required: false,
    onChange: f => f,
    onFocus: f => f,
    onBlur: f => f
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      floated: false
    };
  }

  onChange = e => {
    this.setState({
      value: e.target.value
    });
    this.props.onChange(e);
  };

  onFocus = e => {
    this.setState({
      floated: true,
      focused: true,
    });
    this.props.onFocus(e);
  };

  onBlur = e => {
    if (e.target.value === "") {
      this.setState({
        floated: false,
      });
    }

    this.setState({
      focused: false,
    });
    this.props.onBlur(e);
  };

  render() {
    const { value, floated, focused } = this.state;

    const {
      type,
      name,
      rows,
      label,
      theme,
      vOffset,
      fullwidth,
      multiline,
      required
    } = this.props;

    const className = classNames({
      textfield: true,
      [`textfield--${theme}`]: true,
      "textfield--fullwidth": fullwidth,
      "textfield--multiline": multiline,
      "textfield--floated": floated,
      "textfield--focused": focused,
      "textfield--v-offset": vOffset,
    });

    return (
      <div className={className} style={this.props.style}>
        <div className="textfield__label">{label}</div>
        {multiline ? (
          <textarea
            className="textfield__input"
            name={name}
            value={value}
            rows={rows}
            required={required}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
        ) : (
          <input
            className="textfield__input"
            type={type}
            name={name}
            value={value}
            required={required}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
        )}
      </div>
    );
  }
}

export default TextField;
